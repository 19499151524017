#about-content{
    background-color: white;
    color: black
}

#about-image>div{
    -webkit-box-shadow: 9px 11px 11px 2px rgba(0,0,0,0.16);
    -moz-box-shadow: 9px 11px 11px 2px rgba(0,0,0,0.16);
    box-shadow: 9px 11px 11px 2px rgba(0,0,0,0.16);
}

#about-image img{
    height: 100%; 
    width: auto; 
    text-align: center
}

#about .title::before{
    content: " ";
    display: inline-block;
    width: 20px;
    height: 70px;
    margin-left: 15px;
    padding: 1px;
    margin-right: 10px;
    background-color: black;
}
#about .title h3{
    padding-top: 15px;
}

#about-education img{
    width:70%; 
    height: auto
}

#about-social ul{
    list-style: none;
}
#about-social ul>li{
    border: 2px solid black;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    margin: 10px auto;
    padding-top: 10px;
    font-size: 120%;
    background-color: white;
}

#about-social ul>li>a{
    text-decoration: none;
    color: black;
    height: 50px;
    width: 50px;
}
#about-social ul>li:hover{
    background-color: black;
}
#about-social ul>li:hover>a{
    color: white;
}

@media screen and (max-width: 1200px){
    #about-image{
        width: 50%; 
    }
}
@media screen and (max-width: 1024px){
    #about-image{
        width: 75%; 
    }
}

@media screen and (max-width: 991px){
    #about-image{
        width: 50%;
        height: 100%
    }
    #about-education img{
        width:250px;
        height: auto
    }
    #quote1 h3{
        font-size: 160%;
    }
    #quote1 span.h3{
        font-size: 140%;
    }
    #quote1 p{
        font-size: 120%;
    }
}

@media screen and (max-width: 991px){
    #about-image{
        width: 80%;
    }
}
@media screen and (max-width: 720px){
    #about-image{
        width: 75%;
    }
    #quote1 h3{
        font-size: 110%;
    }
    #quote1 span.h3{
        font-size: 100%;
    }
    #quote1 p{
        font-size: 85%;
    }
}
@media screen and (max-width: 600px){
    #about-image{
        width: 100%;
    }
}
@media screen and (max-width: 480px){
    #quote1 h3{
        font-size: 100%;
    }
    #quote1 span.h3{
        font-size: 90%;
    }
    #quote1 p{
        font-size: 85%;
    }
}