#my-certifications h2{
    color: rgb(187, 127, 247);
    display: inline-block;
    width: 90%;
    text-transform: uppercase;
    position: relative;
    padding-left: 60px;
    padding-top: 15px;
}

#my-certifications h2::before{
    content: " ";
    display: inline-block;
    width: 20px;
    height: 70px;
    margin-left: 15px;
    padding: 1px;
    margin-right: 10px;
    background-color: rgb(187, 127, 247);
    position: absolute;
    top:0;
    left: 0;
}

#my-certifications .cert-container{
    overflow-x: scroll;
    white-space: nowrap;
}

#my-certifications .cert-card{
    position: relative; 
    background-color: white;
    display: inline-block;
    height: 150px; 
    width: 25%; 
    margin: 2px;
}

#my-certifications .cert-container::-webkit-scrollbar {
    width: 20px;
}

/* Track */
#my-certifications .cert-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
#my-certifications .cert-container::-webkit-scrollbar-thumb {
    background: white; 
    border-radius: 10px;
}

/* Handle on hover */
#my-certifications .cert-container::-webkit-scrollbar-thumb:hover {
    background: white; 
}
  
#my-certifications .cert-card>.card-overlay{
    display: flex;
    position: absolute; 
    top: 0;
    width: 100%; 
    height: 100%; 
    background-color: rgba(0,0,0,0.5); 
    z-index: 2;
    justify-content: center;
    visibility: hidden;
    opacity: 0;
}

#my-certifications .cert-container:hover .cert-card:hover{
    transform: scale(1.1);
    transition: 1s;
    z-index: 1
}
#my-certifications .cert-card:hover>.card-overlay, #my-certifications .cert-container .cert-card.active>.card-overlay{
    visibility: visible;
    opacity: 1;
    transition: 1s;
}

#my-certifications .cert-container .cert-card:not(:hover){
    transform: scale(0.9);
    transition: 1s;
}

#my-certifications .cert-card>.card-img{height: 100%; width:100%; position:relative}
#my-certifications .cert-card>.card-img>img{
    height: 100%; 
    width: 100%
}

#my-certifications .cert-card>.card-overlay button{
    padding: 10px 30px; 
    border-radius: 50px;
    border: 1px solid white; 
    background-color: transparent;
    color: white;
}
#my-certifications .cert-card>.card-overlay button:hover{
    color: black;
    background-color: white;
}
@media only screen and (max-width:991px){
    #my-certifications .cert-card{
        width: 30%;
    }
    #my-certifications .cert-container{
        height: 250px;
    }
}
@media only screen and (max-width:860px){
    #my-certifications .cert-card{
        width: 50%;
    }    
}
@media only screen and (max-width:720px){
    #my-certifications .cert-card{
        width: 75%;
    }
}