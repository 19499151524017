*{
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
body{
    position: relative;
    width: 100%;
    overflow-x: hidden;
}
section{
    width: 100%;
}

.know-more-btn a{
    text-decoration: none;
    color: black;
}
.know-more-btn a:hover{
    text-decoration: underline;
}

.abs-btn {
    position: fixed;
    font-size: 80%;
    bottom: 10px;
    padding: 10px;
    height: 60px;
    width: 60px;
    text-align: center;
    border: 1px solid transparent;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 50%;
    z-index: 999
}
.abs-btn:hover {
    cursor: pointer;
    border: 1px solid black;
    background-color: white;
    color: black
}
.abs-btn small{
    display: block;
}


.top-btn{
    right: 10px;
}
.scrolldown-btn{
    right: 80px
}
.scrolldown-btn:disabled, .scrolldown-btn:disabled:hover{
    background-color: rgb(71, 71, 71);
}

.hr-white{
    width: 25%;
    height: 3px;
    border-radius: 50%;
    background-color: white;
    margin: 15px auto;
}
section{
    overflow-x: hidden;
}
@media only screen and (max-width: 1400px){
    .display-md-none, .pagebar-region{
        display: none;
    }
}

@media only screen and (max-width:720px){
    #quote2 .h3{
        font-size: 120%;
    }
    #quote2 .h5{
        font-size: 100%;
    }
}