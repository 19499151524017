#home{
    position: relative;
}
#introduction, #intro-video>.row{
    display: -webkit-flex; /* Safari 6.1+ */
    display: flex;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-flex-flow: column;
    flex-flow: column;
}
#introduction{
    text-transform: uppercase;
}
#introduction>.title, #intro-video .title{
    -webkit-order: 2; /* Safari 6.1+ */
    order: 2;
    width: 100%;
    color: white;
    
}
#intro-video .title{
    -webkit-text-stroke: 1px black;
}
#introduction>.title::before{
    content: " ";
    display: inline-block;
    width: 15px;
    height: 60px;
    padding: 1px;
    margin-right: 10px;
    background-color: white;
}
#introduction>.title h2{
    font-size: 200%;
    font-weight: 300;
    padding-top: 7px;
}

#introduction>.speech, #intro-video .content{
    -webkit-order: 1; /* Safari 6.1+ */
    order: 1;
    width: 100%;
}
#introduction>.speech p{
    font-size: 135%;
    color: rgb(187, 127, 247);
    font-weight: 200;
}
#introduction>.speech .first-name, #introduction>.speech .last-name{
    display: block;
    font-size: 140%;
    color: #FFCCE6;
    font-weight: 300;
}
#introduction button{
    padding: 10px 30px;
    font-size: 85%;
    border-radius: 50px;
    background-color: white;
    outline: none;
    border: 1px solid white;
    color: black;
    transition: background-color 0.5s, color 0.5s;
}
#introduction button:hover{
    background-color: transparent;
    color: white;
}


/* --- Video --- */
#intro-video{
    text-align: center;
}
#intro-video .pendulum{
    order: 0;
    position: absolute;
    top: 0;
    right: 22.5%;
    height: 150px;
    color: white;
}
#intro-video .title{
    font-size: 120%;
    position: relative;
}
#intro-video .title>p{
    font-size: 135%;
    font-weight: 500;
    margin-top: -20px;
    margin-left: 275px;
}
#intro-video .content::after{
    content: " ";
    display: inline-block;
    margin-left: 10%;
    height: 200px;
    width: 1px;
    background-color: white;
}
#intro-video iframe{
    width: 500px;
    height: 275px;
}


@media only screen and (max-width:1200px){
    #introduction>.title{
        order: 1
    }
    #introduction>.speech{
        order: 2
    }
}

@media only screen and (max-width:720px){
    #intro-video iframe{
        width: 400px;
        height: 205px;
    }
    #intro-video .content::after{
        display:none;
    }
}

@media only screen and (max-width:480px){
    #intro-video iframe {
        width: 100%;
        height: 200px;
    }
    #intro-video .title>p{
        margin-top: 0;
        margin-left: 0;
    }
}
#home{
    z-index: 10;
}
.pagebar-region{
    position: relative;
}

.pagebar-region>div{
    position: fixed;
    top: 50px;
    left: 0;
}

.pagebar-region>div>ul{
    list-style: none;
    padding: 0;
    padding-left: 15px;
}
.pagebar-region>div>ul>li{
    display: block;
    padding: 5px 0;
}
.pagebar-region>div>ul>li::before{
    display: block;
    content: " ";
    margin: 5px auto;
    width: 2px;
    height: 40px;
    border-radius: 50%;
    background-color: white;
}
.pagebar-region>div>ul>li:first-child::before{
    display: none;
}
.pagebar-region>div>ul>li>a{
    text-decoration: none;
    color: white;
}
.pagebar-region>div>ul>li.visited>a {
    color:pink;
}
.pagebar-region>div>ul>li.visited::before{
    background-color:pink;
}