#navigation-bar{
    display: flex;
    justify-content: center;
}
#navigation-bar>.row{
    margin-top: 10px;
    padding: 5px;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.524);
    width: 20%;
    border-radius: 20px;
    transition: width 1s;
    z-index: 999;
    text-align: center;
    transition: width 1s, height 1s, color 1s, background-color 1s;
}

#navigation-bar.open>.row{
    width: 90%;
    text-align: left;
    transition: width 1s, height 1s, color 1s, background-color 1s;
}

#navigation-bar ul, #navigation-bar h1{
    display: inline-block;
}

#navigation-bar h1{
    padding-top: 10px;
    font-size: 110%;
}

#nav-toggle{float: right;}

#navigation-bar #nav-links{
    display: none;
    padding-top: 5px;   
    transition: height 1s;
}

#navigation-bar.open #nav-links{
    display: inline-block;
}

#nav-links>ul{
    list-style: none;
    font-size: 90%;
    margin-top: 8px;
}
#nav-links>ul>li{
    display: inline;
    padding: 20px;
}
#nav-links>ul>li>a{
    text-decoration: none;
    color: white;
}
#nav-links>ul>li>a:hover{
    text-decoration: underline;
}

#nav-expand{
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    font-size: 120%;
    transition: transform 1s;
}
#navigation-bar.open #nav-expand{
    margin-top: 5px;
}
#nav-expand:hover{
    cursor:pointer;
}

#navigation-bar.open #nav-expand{
    transform: rotateZ(180deg);
    transition: transform 1s;
}

@media only screen and (max-width:1200px){
    #navigation-bar>.row{
        width: 40%
    }
}
@media only screen and (max-width:900px){
    #navigation-bar>.row{
        width: 90%
    }
}
@media only screen and (max-width:860px){
    #nav-links>ul>li{
        padding: 10px;
    }
    #navigation-bar.open>.row{
        background-color: white;
        color: black;
        -webkit-box-shadow: 0px 5px 7px 5px rgba(0,0,0,0.15);
        -moz-box-shadow: 0px 5px 7px 5px rgba(0,0,0,0.15);
        box-shadow: 0px 5px 7px 5px rgba(0,0,0,0.15);
    }
    #navigation-bar.open *{
        color: black;
    }
    #navigation-bar #nav-toggle{
        float: right;
    }
    #navigation-bar.open #nav-toggle{
        float: none;
    }
    #navigation-bar.open #nav-expand{
        float: right;
        margin-top: -35px;
        transition: transform 1s;
    }
    #navigation-bar.open #nav-links>ul>li{
        display:block;
    }
    #navigation-bar #nav-toggle{
        display:inline-block;
    }
    #navigation-bar.open #nav-toggle{
        display:block;
    }
}