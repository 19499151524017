#my-creations .title::before{
    content: " ";
    display: inline-block;
    width: 20px;
    height: 70px;
    margin-left: 15px;
    padding: 1px;
    margin-right: 10px;
    background-color: black;
}
#my-creations .title>h2{
    display: inline-block;
    width: 90%;
    padding-top: 20px;
    text-transform: uppercase;
}


#my-creations .presentation{
    display: -webkit-flex; /* Safari 6.1+ */
    display: flex;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    -webkit-flex-flow: column;
    flex-flow: column;
}

#my-creations .presentation>.content{
    -webkit-order: 1; /* Safari 6.1+ */
    order: 1;
}
#my-creations .project-row{
    display: flex;
    overflow-x: hidden;
}
#my-creations .project-row>.presentation{
    -webkit-order: 1; /* Safari 6.1+ */
    order:1;
}
#my-creations .project-row>.description{
    -webkit-order: 2; /* Safari 6.1+ */
    order:2;
}

#my-creations .presentation>h3{
    text-align: right;
    position: relative;
    -webkit-order: 1; /* Safari 6.1+ */
    order: 1;
}

#my-creations .presentation>h3::after{
    content: " ";
    display: inline-block;
    width: 20px;
    height: 70px;
    margin-top: 20px;
    margin-right: 15px;
    padding: 1px;
    margin-left: 10px;
    background-color: black;
    position:absolute;
    top:-35px;
}

#my-creations .presentation>.content>iframe{
    height: 250px;
    width: 400px;
}

#my-creations .project-container{
    overflow-x: scroll; 
    white-space: nowrap;
}

#my-creations .project-container::-webkit-scrollbar {
    width: 20px;
}

/* Track */
#my-creations .project-container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}

/* Handle */
#my-creations .project-container::-webkit-scrollbar-thumb {
    background: purple; 
    border-radius: 10px;
}

/* Handle on hover */
#my-certifications .cert-container::-webkit-scrollbar-thumb:hover {
    background: rebeccapurple; 
}

#my-creations .project-container>.span{
    display: inline-block;
    padding: 2px;
    width: 25%;
    font-size: 100%;
}
#my-creations .project-container>.span>div{
    height: 150px; 
    background-color: rgb(51, 127, 155);
}
#my-creations .project-container>.span>div>h3{
    justify-content: center; 
    vertical-align: middle;
    font-size: 110%;
}
#my-creations .project-container>.span>div button, #my-creations .project-container>.span>div a{
    padding: 10px 30px;
    border-radius: 50px;
    outline: none;
    background-color: white;
    border: 1px solid white;
    color: black;
    transition: 1s;
}
#my-creations .project-container>.span>div button:hover, #my-creations .project-container>.span>div a{
    background-color: black;
    border: 1px solid black;
    color: white;
    transition: 1s;
}

#my-creations .project-container>.github-profile>div{
    background-color: transparent;
}
#my-creations .project-container>.github-profile h3{
    color: black;
}

#my-creations .project-container>.github-profile>div button{
    background-color: white;
    border: 1px solid black;
    color: black
}
#my-creations .project-container>.github-profile>div button:hover{
    background-color: black;
    color: white;
    border: 1px solid black;
}

@media only screen and (max-width:1300px){
    #my-creations .project-container>.span>div>h3{
        font-size: 75%;
    }
}
@media only screen and (max-width:991px){
    #my-creations .presentation>.content{
        -webkit-order: 2; /* Safari 6.1+ */
        order: 2;
    }
    #my-creations .presentation>h3{
        -webkit-order: 1; /* Safari 6.1+ */
        order: 1;
        margin-right: 30px;
    }
    #my-creations .project-row>.presentation{
        -webkit-order: 1; /* Safari 6.1+ */
        order:1;
    }
    #my-creations .project-row>.description{
        -webkit-order: 2; /* Safari 6.1+ */
        order:2;
    }
    #my-creations .project-container>.span{
        width: 30%;
    }
}
@media only screen and (max-width:860px){
    #my-creations .project-container>.span{
        width: 50%;
    }
    #my-creations h2.h3{
        width: 70%;
    }
    #my-creations .project-container>.span>div>h3{
        font-size: 100%;
    }
}
@media only screen and (max-width:720px){
    #my-creations .project-container>.span{
        width: 75%;
    }
}
@media only screen and (max-width:480px){
    #my-creations .presentation>.content>iframe{
        width: 100%;
        height: 200px;
    }
}