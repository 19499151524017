.contact-buttons button{
    padding: 10px 30px;
    min-width: 150px;
    background-color: white;
    border: none;
    outline: none;
    border-radius: 5px;
    margin: 10px;
}
.contact-buttons button:hover{
    color: white !important;
}


.linkedin-btn:hover{
    background-color: #006192;
}
.gmail-btn:hover, .youtube-btn:hover{
    background-color: #D44638;
}
.facebook-btn:hover{
    background-color: #4267B2;   
}


#form-space {
    text-align: center;
}
#form-space .label-container{
    color: black;
    text-align: left;
    font-size: 110%;
    padding: 4px;
}
#form-space input, #form-space textarea{
    background-color: #f7f1e3;
    border: 1px solid #656565;
    padding: 8px;
    font-size: 110%;
    width: 95%;
    margin: 7px;
    color: rgb(0, 0, 0);
}
#form-space input::placeholder{
    color: rgb(81, 81, 81);
}

.send-btn{
    border: 1px solid black;
    background-color: black !important;
    padding: 18px 30px;
    outline: none;
    color: white !important;
    width: 150px !important;
    border-radius: 50px;
}

#form-space>.row{
    transform: scale(0.8);
}
#form-space input[type=submit]{
    transform: scale(1.2);
}
@media only screen and (max-width:991px){
    #form-space>.row{
        transform: scale(1);
    }
    #form-space input[type=submit]{
        transform: scale(1);
    }
}